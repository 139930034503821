import React, { useContext } from 'react';

import { useRouter } from 'next/router';
import { BreadcrumbJsonLd } from 'next-seo';
import styled from 'styled-components';

import { FooterDefault } from '../components/Toolkit/Footer/FooterDefault';
import { Header } from '../components/Toolkit/Header/Header';
import {
  AdPerformanceToolFooterNavLinkConfig,
  DataHubNavLinkConfig,
  DataHubNavLinkConfig_v2,
  FooterNavLinkConfig,
  IntelToolFooterNavLinkConfig,
  OffersFooterNavLinkConfig,
  VTFooterNavLinkConfig,
  IntelToolFooterNavLinkConfigV2,
  VTFooterNavLinkConfigV2,
  OffersFooterNavLinkConfigV2,
} from '../config';
import { getNavigationForSection } from '../config/headerNavigation';
import { useBuyingBudgetContext } from '../contexts/buyingBudgetContext';
import { UserDetailsContext } from '../contexts/userDetailsContext';

import { Breadcrumbs } from '../types';

type ConfigProps = {
  pageVariant?: string;
  BASE_URL: string;
  className?: string;
};

type HeaderLayoutProps = {
  CDN_URL_STATIC_DIRECTORY: string;
  BASE_URL: string;
  pageVariant?: string;
  headerTheme?: 'COMMERCIAL' | 'ALTERNATIVE' | 'RESIDENTIAL';
  category?: string;
  hideFooter?: boolean;
  asPath?: string;
  isMapView?: boolean;
};

type FooterLayoutProps = {
  BASE_URL: string;
  pageVariant?: string;
  hideFooter?: boolean;
  breadcrumbs?: Breadcrumbs;
  isMobileView?: boolean;
};

export type MainProps = {
  children: any;
  breadcrumbs?: Breadcrumbs;
  backgroundColor?: string;
  isMapView?: boolean;
} & HeaderLayoutProps;

const Main = styled.main<{ backgroundColor?: string }>`
  min-height: calc(100vh - 56px);
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#ffffff'};
  @media only screen and (min-width: 900px) {
    min-height: calc(100vh - 216px);
  }
`;

const buildItemList = (breadcrumbs: Breadcrumbs, BASE_URL: string) =>
  breadcrumbs.map((breadcrumb, index) => ({
    position: index + 1,
    name: breadcrumb.displayValue,
    item: `${BASE_URL}${breadcrumb.url}`,
  }));

const HeaderWrapper = ({
  pageVariant,
  headerTheme,
  asPath,
  isMapView = false,
}: HeaderLayoutProps) => {
  const { isUserLoggedIn } = useContext(UserDetailsContext);
  const { hasBuyingBudget } = useBuyingBudgetContext();
  const navigation = getNavigationForSection({
    pageVariant,
    alternativeStyle: headerTheme && headerTheme === 'ALTERNATIVE',
    isUserLoggedIn,
    hasBuyingBudget,
  });

  const standardNavConfig = navigation.standardNavConfig
    ? navigation.standardNavConfig
    : [];

  const mobileStandardNavConfig = navigation?.mobileStandardNavConfig
    ? navigation?.mobileStandardNavConfig
    : [];

  const mortgagesNavConfig = navigation.mortgagesNavConfig
    ? navigation.mortgagesNavConfig({
        isUserLoggedIn: isUserLoggedIn,
        hasBuyingBudget: hasBuyingBudget,
      })
    : [];

  return (
    <Header
      isUserLoggedIn={isUserLoggedIn}
      themeName={headerTheme}
      standardNavConfig={[...standardNavConfig, ...mortgagesNavConfig]}
      mobileStandardNavConfig={[
        ...mobileStandardNavConfig,
        ...mortgagesNavConfig,
      ]}
      navigation={navigation}
      ctaNavConfig={navigation.ctaNavConfig || undefined}
      logoConfig={navigation.logoConfig}
      asPath={asPath || ''}
      hasFixedPosition={isMapView}
    />
  );
};

const renderFooterNavLinks = ({ pageVariant, BASE_URL }: ConfigProps) => {
  switch (pageVariant) {
    case 'VALUATION_TOOL':
      return VTFooterNavLinkConfig(BASE_URL);
    case 'VALUATION_TOOL_V2':
      return VTFooterNavLinkConfigV2(BASE_URL);
    case 'AGENT_OFFERS':
      return OffersFooterNavLinkConfig(BASE_URL);
    case 'AGENT_OFFERS_V2':
      return OffersFooterNavLinkConfigV2(BASE_URL);
    case 'INTEL_TOOL':
      return IntelToolFooterNavLinkConfig(BASE_URL);
    case 'INTEL_TOOL_V2':
      return IntelToolFooterNavLinkConfigV2(BASE_URL);
    case 'AD_PERFORMANCE_TOOL':
      return AdPerformanceToolFooterNavLinkConfig(BASE_URL);
    case 'DATA_HUB':
      return DataHubNavLinkConfig(BASE_URL);
    case 'DATA_HUB_v2':
      return DataHubNavLinkConfig_v2(BASE_URL);
    case 'PREMIER_PARTNER':
      return DataHubNavLinkConfig(BASE_URL);
    default:
      return FooterNavLinkConfig(BASE_URL);
  }
};

export const renderFooter = ({
  BASE_URL,
  breadcrumbs,
  hideFooter,
  isMobileView,
  pageVariant,
}: FooterLayoutProps) => {
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const navLinks = renderFooterNavLinks({ pageVariant, BASE_URL });

  switch (pageVariant) {
    default: {
      return (
        <FooterDefault
          BASE_URL={'/'}
          navLinks={navLinks}
          hideFooterOnMobile={hideFooter}
          breadcrumbs={breadcrumbs}
          isMobileView={isMobileView}
        />
      );
    }
  }
};

export const DefaultLayout = ({
  children,
  CDN_URL_STATIC_DIRECTORY,
  BASE_URL,
  pageVariant,
  headerTheme,
  category,
  hideFooter,
  breadcrumbs,
  backgroundColor,
  isMapView = false,
}: MainProps) => {
  const copyOfBreadCrumbsWithOutLastItem = breadcrumbs ? breadcrumbs : [];
  const routerHook = useRouter();
  const asPath = routerHook.asPath;

  return (
    <>
      <HeaderWrapper
        pageVariant={pageVariant}
        headerTheme={headerTheme}
        CDN_URL_STATIC_DIRECTORY={CDN_URL_STATIC_DIRECTORY}
        BASE_URL={BASE_URL}
        category={category}
        asPath={asPath}
        isMapView={isMapView}
      />
      <Main role="main" backgroundColor={backgroundColor}>
        {children}
      </Main>
      {copyOfBreadCrumbsWithOutLastItem.length ? (
        <BreadcrumbJsonLd
          itemListElements={buildItemList(
            copyOfBreadCrumbsWithOutLastItem,
            BASE_URL,
          )}
        />
      ) : null}

      {renderFooter({ pageVariant, BASE_URL, hideFooter, breadcrumbs })}
    </>
  );
};
