import {
  BASE_URL,
  CDN_URL_STATIC_DIRECTORY,
  LEGACY_REDIRECT_URL,
} from '../helpers/utils';

import { SEARCH_URLS } from '../types';

type HeaderNavConfigProps = {
  alternativeStyle?: boolean;
  pageVariant?: string;
  isUserLoggedIn?: boolean;
  hasBuyingBudget?: boolean;
};

export type NavigationItemProps = {
  title: string;
  /**
   * Url to navigate to, if provided. If undefined, the item will not be clickable - good for
   * non-navigable headings with subItems.
   */
  href?: string;
  basePath?: string | string[];
  dontShowActiveForSubPaths?: boolean;
  subItems?: NavigationSubItemProps;
  onlyShowOnDesktop?: boolean;
  hideLinkOnMobile?: boolean;
  hideLinkOnDesktop?: boolean;
  opensInNewTab?: boolean;
  dataTracking?: string;
  hasNewLabel?: boolean;
  hasNewSubtitleLabel?: boolean;
};

type NavigationSubItemProps = Array<
  // SubItems cannot have more subItems.
  Omit<NavigationItemProps, 'subItems'> & {
    /**
     * Using next/link will pre-fetch the page, while a normal anchor will not. There are times where
     * the pre-fetching is not desired, such as when the linked page is not a common navigation for
     * users e.g. place ad (small usage) vs search (high usage).
     *
     * TODO: As of Mar 6, 2025, we also have a bug where we are manually mutating the express Response
     * object in our getServerSideProps functions which are auth-gated. This means next/link cannot
     * properly redirect the use to the login page. For pages that are login-gated, we should use
     * anchor until we fix this bug.
     *
     * See: https://distilledsch.tpondemand.com/entity/105534-dont-mutate-the-express-response-object
     */
    renderAs?: 'next/link' | 'anchor';
  }
>;

export type StandardNavConfigProps = {
  isUserLoggedIn?: boolean;
  hasBuyingBudget?: boolean;
};

export type HeaderNavConfig = {
  standardNavConfig?: NavigationItemProps[];
  mortgagesNavConfig?: ({
    isUserLoggedIn,
    hasBuyingBudget,
  }: StandardNavConfigProps) => NavigationItemProps[];
  ctaNavConfig?: NavigationItemProps;
  unAuthenticatedAccountNavConfig?: NavigationItemProps | NavigationItemProps[];
  mobileUnAuthenticatedAccountNavConfig?: NavigationItemProps[];
  authenticatedAccountNavConfig?: NavigationItemProps | NavigationItemProps[];
  placeAdConfig?: NavigationItemProps | NavigationItemProps[];
  mobileStandardNavConfig?: NavigationItemProps[];
};

export type LogoConfig = {
  url: string;
  href: string;
  alt: string;
};

const adPerformaceNavigation: HeaderNavConfig = {
  standardNavConfig: [
    {
      title: 'Multi-Unit Rentals',
      href: '/agents/adperformance/mur',
      basePath: 'adperformance/mur',
    },
    {
      title: 'New Homes',
      href: '/agents/adperformance/new-homes',
      basePath: 'adperformance/new-homes',
    },
  ],
  mobileStandardNavConfig: [
    {
      title: 'Multi-Unit Rentals',
      href: '/agents/adperformance/mur',
      basePath: 'adperformance/mur',
    },
    {
      title: 'New Homes',
      href: '/agents/adperformance/new-homes',
      basePath: 'adperformance/new-homes',
    },
  ],

  unAuthenticatedAccountNavConfig: {
    title: 'Sign in',
    href: '/agents/auth/authenticate',
  },
};

const valuationToolNavigation: HeaderNavConfig = {
  standardNavConfig: [
    {
      title: 'Property Price Register',
      href: '/agents/valuation-tool-property-price-register',
      basePath: '/valuation-tool-property-price-register',
    },
    {
      title: 'Residential Sales',
      href: '/agents/valuation-tool-residential-sales',
      basePath: '/valuation-tool-residential-sales',
    },
    {
      title: 'Residential Rent',
      href: '/agents/valuation-tool-residential-rent',
      basePath: '/valuation-tool-residential-rent',
    },
    {
      title: 'Commercial',
      href: '/agents/valuation-tool-commercial',
      basePath: '/valuation-tool-commercial',
    },
  ],
  mobileStandardNavConfig: [
    {
      title: 'Property Price Register',
      href: '/agents/valuation-tool-property-price-register',
      basePath: '/valuation-tool-property-price-register',
    },
    {
      title: 'Residential Sales',
      href: '/agents/valuation-tool-residential-sales',
      basePath: '/valuation-tool-residential-sales',
    },
    {
      title: 'Residential Rent',
      href: '/agents/valuation-tool-residential-rent',
      basePath: '/valuation-tool-residential-rent',
    },
    {
      title: 'Commercial',
      href: '/agents/valuation-tool-commercial',
      basePath: '/valuation-tool-commercial',
    },
  ],
  unAuthenticatedAccountNavConfig: {
    title: 'Sign in',
    href: '/agents/auth/authenticate',
  },
};

const valuationToolNavigationV2: HeaderNavConfig = {
  standardNavConfig: [
    {
      title: 'Property Price Register',
      href: '/agent-v2/valuation-tool-property-price-register',
      basePath: '/valuation-tool-property-price-register',
    },
    {
      title: 'Residential Sales',
      href: '/agent-v2/valuation-tool-residential-sales',
      basePath: '/valuation-tool-residential-sales',
    },
    {
      title: 'Residential Rent',
      href: '/agent-v2/valuation-tool-residential-rent',
      basePath: '/valuation-tool-residential-rent',
    },
    {
      title: 'Commercial',
      href: '/agent-v2/valuation-tool-commercial',
      basePath: '/valuation-tool-commercial',
    },
  ],
  mobileStandardNavConfig: [
    {
      title: 'Property Price Register',
      href: '/agent-v2/valuation-tool-property-price-register',
      basePath: '/valuation-tool-property-price-register',
    },
    {
      title: 'Residential Sales',
      href: '/agent-v2/valuation-tool-residential-sales',
      basePath: '/valuation-tool-residential-sales',
    },
    {
      title: 'Residential Rent',
      href: '/agent-v2/valuation-tool-residential-rent',
      basePath: '/valuation-tool-residential-rent',
    },
    {
      title: 'Commercial',
      href: '/agent-v2/valuation-tool-commercial',
      basePath: '/valuation-tool-commercial',
    },
  ],
  unAuthenticatedAccountNavConfig: {
    title: 'Sign in',
    href: '/agent-v2/auth/authenticate',
  },
};

const agentOffersNavigation: HeaderNavConfig = {
  standardNavConfig: [
    {
      title: 'Residential Sales',
      href: '/agents/offers/residential-for-sale',
      basePath: '/offers/residential-for-sale',
    },
    {
      title: 'Commercial Sales',
      href: '/agents/offers/commercial-for-sale',
      basePath: '/offers/commercial-for-sale',
    },
    {
      title: 'Back to Agent Admin',
      href: '/agents',
      basePath: '',
    },
  ],
  mobileStandardNavConfig: [
    {
      title: 'Residential Sales',
      href: '/agents/offers/residential-for-sale',
      basePath: '/offers/residential-for-sale',
    },
    {
      title: 'Commercial Sales',
      href: '/agents/offers/commercial-for-sale',
      basePath: '/offers/commercial-for-sale',
    },
    {
      title: 'Back to Agent Admin',
      href: '/agents',
      basePath: '',
    },
  ],
};

const agentOffersNavigationV2: HeaderNavConfig = {
  standardNavConfig: [
    {
      title: 'Residential Sales',
      href: '/agent-v2/offers/residential-for-sale',
      basePath: '/offers/residential-for-sale',
    },
    {
      title: 'Commercial Sales',
      href: '/agent-v2/offers/commercial-for-sale',
      basePath: '/offers/commercial-for-sale',
    },
    {
      title: 'Back to Agent Admin',
      href: '/agent-v2',
      basePath: '',
    },
  ],
  mobileStandardNavConfig: [
    {
      title: 'Residential Sales',
      href: '/agent-v2/offers/residential-for-sale',
      basePath: '/offers/residential-for-sale',
    },
    {
      title: 'Commercial Sales',
      href: '/agent-v2/offers/commercial-for-sale',
      basePath: '/offers/commercial-for-sale',
    },
    {
      title: 'Back to Agent Admin',
      href: '/agent-v2',
      basePath: '',
    },
  ],
};

const agentV2IntelTool = [
  {
    title: 'Supply',
    href: '/agent-v2/bi/supply',
    basePath: '/bi/supply',
  },
  {
    title: 'Demand',
    href: '/agent-v2/bi/demand',
    basePath: '/bi/demand',
  },
  {
    title: 'Top Performing Ads',
    href: '/agent-v2/bi/top-performing',
    basePath: '/bi/top-performing',
  },
  {
    title: 'My Subscription',
    href: '/agent-v2/bi/subscription',
    basePath: '/bi/subscription',
  },
];
const intelToolNavigationV2: HeaderNavConfig = {
  standardNavConfig: agentV2IntelTool,
  mobileStandardNavConfig: agentV2IntelTool,
  unAuthenticatedAccountNavConfig: {
    title: 'Sign in',
    href: '/agent-v2/auth/authenticate',
  },
};

const standardIntelTool = [
  {
    title: 'Supply',
    href: '/agents/bi/supply',
    basePath: '/bi/supply',
  },
  {
    title: 'Demand',
    href: '/agents/bi/demand',
    basePath: '/bi/demand',
  },
  {
    title: 'Top Performing Ads',
    href: '/agents/bi/top-performing',
    basePath: '/bi/top-performing',
  },
  {
    title: 'My Subscription',
    href: '/agents/bi/subscription',
    basePath: '/bi/subscription',
  },
];

const intelToolNavigation: HeaderNavConfig = {
  standardNavConfig: standardIntelTool,
  mobileStandardNavConfig: standardIntelTool,
  unAuthenticatedAccountNavConfig: {
    title: 'Sign in',
    href: '/agents/auth/authenticate',
  },
};

const dataHubNavigationV2: HeaderNavConfig = {
  standardNavConfig: [
    {
      title: 'Market Insights',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-sales',
          basePath: '/market-insights-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-rent',
          basePath: '/market-insights-rent',
        },
        {
          title: 'New Homes',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-new-homes',
          basePath: '/market-insights-new-homes',
        },
        {
          title: 'Multi-unit Rental',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-mur',
          basePath: '/market-insights-mur',
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-commercial',
          basePath: '/market-insights-commercial',
        },
      ],
    },
    {
      title: 'Daft Report Pro',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/daft-report-pro-sales',
          basePath: '/daft-report-pro-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/daft-report-pro-rent',
          basePath: '/daft-report-pro-rent',
        },
      ],
    },
    {
      title: 'Property Valuation Tool',
      href: '/agent-v2/valuation-tool',
      basePath: '/valuation-tool',
      opensInNewTab: true,
      subItems: [
        {
          title: 'Property Price Register',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-property-price-register',
          basePath: '/valuation-tool-property-price-register',
          opensInNewTab: true,
        },
        {
          title: 'Residential Sale',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-residential-sales',
          basePath: '/valuation-tool-residential-sales',
          opensInNewTab: true,
        },
        {
          title: 'Residential Rent',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-residential-rent',
          basePath: '/valuation-tool-residential-rent',
          opensInNewTab: true,
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-commercial',
          basePath: '/valuation-tool-commercial',
          opensInNewTab: true,
        },
      ],
    },
  ],
  mobileStandardNavConfig: [
    {
      title: 'Market Insights',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-sales',
          basePath: '/market-insights-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-rent',
          basePath: '/market-insights-rent',
        },
        {
          title: 'New Homes',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-new-homes',
          basePath: '/market-insights-new-homes',
        },
        {
          title: 'Multi-unit Rental',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-mur',
          basePath: '/market-insights-mur',
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/market-insights-commercial',
          basePath: '/market-insights-commercial',
        },
      ],
    },
    {
      title: 'Daft Report Pro',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/daft-report-pro-sales',
          basePath: '/daft-report-pro-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/datahub/daft-report-pro-rent',
          basePath: '/daft-report-pro-rent',
        },
      ],
    },
    {
      title: 'Property Valuation Tool',
      href: '/agent-v2/valuation-tool',
      basePath: '/valuation-tool',
      opensInNewTab: true,
      subItems: [
        {
          title: 'Property Price Register',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-property-price-register',
          basePath: '/valuation-tool-property-price-register',
          opensInNewTab: true,
        },
        {
          title: 'Residential Sale',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-residential-sales',
          basePath: '/valuation-tool-residential-sales',
          opensInNewTab: true,
        },
        {
          title: 'Residential Rent',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-residential-rent',
          basePath: '/valuation-tool-residential-rent',
          opensInNewTab: true,
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agent-v2/valuation-tool-commercial',
          basePath: '/valuation-tool-commercial',
          opensInNewTab: true,
        },
      ],
    },
  ],
};

const dataHubNavigation: HeaderNavConfig = {
  standardNavConfig: [
    {
      title: 'Market Insights',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-sales',
          basePath: '/market-insights-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-rent',
          basePath: '/market-insights-rent',
        },
        {
          title: 'New Homes',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-new-homes',
          basePath: '/market-insights-new-homes',
        },
        {
          title: 'Multi-unit Rental',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-mur',
          basePath: '/market-insights-mur',
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-commercial',
          basePath: '/market-insights-commercial',
        },
      ],
    },
    {
      title: 'Daft Report Pro',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/daft-report-pro-sales',
          basePath: '/daft-report-pro-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/daft-report-pro-rent',
          basePath: '/daft-report-pro-rent',
        },
      ],
    },
    {
      title: 'Property Valuation Tool',
      href: '/agents/valuation-tool',
      basePath: '/valuation-tool',
      opensInNewTab: true,
      subItems: [
        {
          title: 'Property Price Register',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-property-price-register',
          basePath: '/valuation-tool-property-price-register',
          opensInNewTab: true,
        },
        {
          title: 'Residential Sale',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-residential-sales',
          basePath: '/valuation-tool-residential-sales',
          opensInNewTab: true,
        },
        {
          title: 'Residential Rent',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-residential-rent',
          basePath: '/valuation-tool-residential-rent',
          opensInNewTab: true,
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-commercial',
          basePath: '/valuation-tool-commercial',
          opensInNewTab: true,
        },
      ],
    },
  ],
  mobileStandardNavConfig: [
    {
      title: 'Market Insights',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-sales',
          basePath: '/market-insights-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-rent',
          basePath: '/market-insights-rent',
        },
        {
          title: 'New Homes',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-new-homes',
          basePath: '/market-insights-new-homes',
        },
        {
          title: 'Multi-unit Rental',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-mur',
          basePath: '/market-insights-mur',
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/market-insights-commercial',
          basePath: '/market-insights-commercial',
        },
      ],
    },
    {
      title: 'Daft Report Pro',
      subItems: [
        {
          title: 'Sales',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/daft-report-pro-sales',
          basePath: '/daft-report-pro-sales',
        },
        {
          title: 'Rent',
          dontShowActiveForSubPaths: true,
          href: '/agents/datahub/daft-report-pro-rent',
          basePath: '/daft-report-pro-rent',
        },
      ],
    },
    {
      title: 'Property Valuation Tool',
      href: '/agents/valuation-tool',
      basePath: '/valuation-tool',
      opensInNewTab: true,
      subItems: [
        {
          title: 'Property Price Register',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-property-price-register',
          basePath: '/valuation-tool-property-price-register',
          opensInNewTab: true,
        },
        {
          title: 'Residential Sale',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-residential-sales',
          basePath: '/valuation-tool-residential-sales',
          opensInNewTab: true,
        },
        {
          title: 'Residential Rent',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-residential-rent',
          basePath: '/valuation-tool-residential-rent',
          opensInNewTab: true,
        },
        {
          title: 'Commercial',
          dontShowActiveForSubPaths: true,
          href: '/agents/valuation-tool-commercial',
          basePath: '/valuation-tool-commercial',
          opensInNewTab: true,
        },
      ],
    },
  ],
};

const PLACE_AD: NavigationItemProps = {
  title: 'Place Ad',
  basePath: '/place-ad',
  subItems: [
    {
      title: 'Residential - For Rent',
      href: '/placead',
      renderAs: 'anchor',
    },
    {
      title: 'Residential - To Share',
      href: '/placead',
      renderAs: 'anchor',
    },
    {
      title: 'Residential - For Sale',
      href: '/placead',
      renderAs: 'anchor',
    },
    {
      title: 'Commercial - For Sale',
      href: '/placead',
      renderAs: 'anchor',
    },
    {
      title: 'Commercial - For Rent',
      href: '/placead',
      renderAs: 'anchor',
    },
    {
      title: 'Parking',
      href: '/placead',
      renderAs: 'anchor',
    },
    {
      title: 'Holiday Homes',
      href: `${LEGACY_REDIRECT_URL}/ad-entry/holiday_home`,
      hideLinkOnMobile: true,
    },
  ],
};

const defaultNavigation = ({
  redirectBackToAfterLogOut = '',
}: {
  redirectBackToAfterLogOut?: string;
}) => {
  return {
    standardNavConfig: [
      {
        title: 'Buy',
        href: `/${SEARCH_URLS.BUY}/ireland`,
        basePath: [SEARCH_URLS.PARKING_BUY, SEARCH_URLS.BUY],
        subItems: [
          {
            title: 'All Property',
            dontShowActiveForSubPaths: true,
            href: `/${SEARCH_URLS.BUY}/ireland`,
            basePath: SEARCH_URLS.BUY,
          },
          {
            title: 'Houses',
            basePath: `${SEARCH_URLS.BUY}/ireland/houses`,
            href: `/${SEARCH_URLS.BUY}/ireland/houses`,
          },
          {
            title: 'Apartments',
            basePath: `${SEARCH_URLS.BUY}/ireland/apartments`,
            href: `/${SEARCH_URLS.BUY}/ireland/apartments`,
          },
          {
            title: 'Online Offers Available',
            basePath: `${SEARCH_URLS.BUY}/ireland/?offersEnabledDisabled=true`,
            href: `/${SEARCH_URLS.BUY}/ireland/?offersEnabledDisabled=true`,
            dataTracking: 'click-online-offers-available',
          },
          {
            title: 'Sites',
            basePath: `${SEARCH_URLS.BUY}/ireland/sites`,
            href: `/${SEARCH_URLS.BUY}/ireland/sites`,
          },
          {
            title: 'Parking Spaces',
            basePath: SEARCH_URLS.PARKING_BUY,
            href: `/${SEARCH_URLS.PARKING_BUY}/ireland`,
          },
          {
            title: 'Buying Tools',
            basePath: 'buying-tools',
            href: `/buying-tools`,
          },
        ],
      },
      {
        title: 'House Prices',
        basePath: `${SEARCH_URLS.SOLD}`,
        href: `/${SEARCH_URLS.SOLD}/ireland`,
        hasNewLabel: true,
        subItems: [
          {
            title: 'What is my home worth?',
            href: `/instant-valuation`,
            opensInNewTab: true,
          },
          {
            title: 'Sold Properties',
            basePath: `${SEARCH_URLS.SOLD}`,
            href: `/${SEARCH_URLS.SOLD}/ireland`,
          },
          {
            title: 'Daft Report',
            href: `/report`,
            opensInNewTab: true,
          },
        ],
      },
      {
        title: 'New Homes',
        basePath: SEARCH_URLS.NEW_HOMES,
        href: `/${SEARCH_URLS.NEW_HOMES}/ireland`,
        subItems: [
          {
            title: 'For Sale',
            basePath: SEARCH_URLS.NEW_HOMES,
            href: `/${SEARCH_URLS.NEW_HOMES}/ireland`,
          },
        ],
      },
      {
        title: 'Rent',
        href: `/${SEARCH_URLS.RENT}/ireland`,
        basePath: [
          SEARCH_URLS.PARKING_RENT,
          SEARCH_URLS.STUDENT_ACCOMMODATION_RENT,
          SEARCH_URLS.RENT,
          SEARCH_URLS.HOLIDAY_HOMES,
        ],
        subItems: [
          {
            title: 'All Property',
            dontShowActiveForSubPaths: true,
            href: `/${SEARCH_URLS.RENT}/ireland`,
            basePath: `${SEARCH_URLS.RENT}/ireland`,
          },
          {
            title: 'Houses',
            basePath: `${SEARCH_URLS.RENT}/ireland/houses`,
            href: `/${SEARCH_URLS.RENT}/ireland/houses`,
          },
          {
            title: 'Apartments',
            basePath: `${SEARCH_URLS.RENT}/ireland/apartments`,
            href: `/${SEARCH_URLS.RENT}/ireland/apartments`,
          },
          {
            title: 'Student Accommodation',
            basePath: SEARCH_URLS.STUDENT_ACCOMMODATION_RENT,
            href: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_RENT}/ireland`,
          },
          {
            title: 'Holiday Homes',
            basePath: `${SEARCH_URLS.HOLIDAY_HOMES}`,
            href: `/${SEARCH_URLS.HOLIDAY_HOMES}/ireland`,
          },
          {
            title: 'Short Term',
            basePath: `${SEARCH_URLS.RENT}/ireland/?leaseLength_to=3`,
            href: `/${SEARCH_URLS.RENT}/ireland/?leaseLength_to=3`,
          },
          {
            title: 'Parking Spaces',
            basePath: SEARCH_URLS.PARKING_RENT,
            href: `/${SEARCH_URLS.PARKING_RENT}/ireland`,
          },
        ],
      },
      {
        title: 'Share',
        href: `/${SEARCH_URLS.SHARE}/ireland`,
        basePath: [SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE, SEARCH_URLS.SHARE],
        subItems: [
          {
            title: 'All Property',
            dontShowActiveForSubPaths: true,
            href: `/${SEARCH_URLS.SHARE}/ireland`,
            basePath: `/${SEARCH_URLS.SHARE}/ireland`,
          },
          {
            title: 'Student Accommodation',
            basePath: SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE,
            href: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE}/ireland`,
          },
        ],
      },
      {
        title: 'Sell',
        href: `https://www.sell.daft.ie/?utm_source=Daft+Sell+Nav&utm_medium=Sell+Menu+button`,
        opensInNewTab: true,
        subItems: [
          {
            title: 'How to sell my home',
            href: `https://sell.daft.ie?utm_source=Daft+Sell+Nav&utm_medium=how+to+sell+my+home+menu+item`,
            opensInNewTab: true,
          },
          {
            title: 'Get the Daft Advantage',
            href: `https://www.advantage.daft.ie?utm_source=Daft+Sell+Nav&utm_medium=get+the+daft+advantage+menu+item`,
            opensInNewTab: true,
          },
        ],
      },
      {
        title: 'Commercial',
        href: '/commercial-property',
        basePath: [SEARCH_URLS.COMMERCIAL_RENT, SEARCH_URLS.COMMERCIAL_BUY],
        subItems: [
          {
            title: 'To Lease',
            basePath: SEARCH_URLS.COMMERCIAL_RENT,
            href: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland`,
          },
          {
            title: 'For Sale',
            basePath: SEARCH_URLS.COMMERCIAL_BUY,
            href: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland`,
          },
        ],
      },
    ] satisfies NavigationItemProps[],
    mobileStandardNavConfig: [
      {
        title: 'Buy',
        href: `/${SEARCH_URLS.BUY}/ireland`,
        basePath: [SEARCH_URLS.PARKING_BUY, SEARCH_URLS.BUY],
        subItems: [
          {
            title: 'All Property',
            dontShowActiveForSubPaths: true,
            href: `/${SEARCH_URLS.BUY}/ireland`,
            basePath: SEARCH_URLS.BUY,
          },
          {
            title: 'Houses',
            basePath: `${SEARCH_URLS.BUY}/ireland/houses`,
            href: `/${SEARCH_URLS.BUY}/ireland/houses`,
          },
          {
            title: 'Apartments',
            basePath: `${SEARCH_URLS.BUY}/ireland/apartments`,
            href: `/${SEARCH_URLS.BUY}/ireland/apartments`,
          },
          {
            title: 'Online Offers Available',
            basePath: `${SEARCH_URLS.BUY}/ireland/?offersEnabledDisabled=true`,
            href: `/${SEARCH_URLS.BUY}/ireland/?offersEnabledDisabled=true`,
            dataTracking: 'click-online-offers-available',
          },
          {
            title: 'Sites',
            basePath: `${SEARCH_URLS.BUY}/ireland/sites`,
            href: `/${SEARCH_URLS.BUY}/ireland/sites`,
          },
          {
            title: 'Parking Spaces',
            basePath: SEARCH_URLS.PARKING_BUY,
            href: `/${SEARCH_URLS.PARKING_BUY}/ireland`,
          },
          {
            title: 'Buying Tools',
            basePath: 'buying-tools',
            href: `/buying-tools`,
          },
        ],
      },
      {
        title: 'House Prices',
        basePath: `${SEARCH_URLS.SOLD}`,
        hasNewLabel: true,
        subItems: [
          {
            title: 'What is my home worth?',
            href: `/instant-valuation`,
            opensInNewTab: true,
          },
          {
            title: 'Sold Properties',
            basePath: `${SEARCH_URLS.SOLD}`,
            href: `/${SEARCH_URLS.SOLD}/ireland`,
          },
          {
            title: 'Daft Report',
            href: `/report`,
            opensInNewTab: true,
          },
        ],
      },
      {
        title: 'New Homes',
        basePath: SEARCH_URLS.NEW_HOMES,
        href: `/${SEARCH_URLS.NEW_HOMES}/ireland`,
        subItems: [
          {
            title: 'For Sale',
            basePath: SEARCH_URLS.NEW_HOMES,
            href: `/${SEARCH_URLS.NEW_HOMES}/ireland`,
          },
        ],
      },
      {
        title: 'Rent',
        href: `/${SEARCH_URLS.RENT}/ireland`,
        basePath: [
          SEARCH_URLS.PARKING_RENT,
          SEARCH_URLS.STUDENT_ACCOMMODATION_RENT,
          SEARCH_URLS.RENT,
          SEARCH_URLS.HOLIDAY_HOMES,
        ],
        subItems: [
          {
            title: 'All Property',
            dontShowActiveForSubPaths: true,
            href: `/${SEARCH_URLS.RENT}/ireland`,
            basePath: `${SEARCH_URLS.RENT}/ireland`,
          },
          {
            title: 'Houses',
            basePath: `${SEARCH_URLS.RENT}/ireland/houses`,
            href: `/${SEARCH_URLS.RENT}/ireland/houses`,
          },
          {
            title: 'Apartments',
            basePath: `${SEARCH_URLS.RENT}/ireland/apartments`,
            href: `/${SEARCH_URLS.RENT}/ireland/apartments`,
          },
          {
            title: 'Student Accommodation',
            basePath: SEARCH_URLS.STUDENT_ACCOMMODATION_RENT,
            href: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_RENT}/ireland`,
          },
          {
            title: 'Holiday Homes',
            basePath: `${SEARCH_URLS.HOLIDAY_HOMES}`,
            href: `/${SEARCH_URLS.HOLIDAY_HOMES}/ireland`,
          },
          {
            title: 'Short Term',
            basePath: `${SEARCH_URLS.RENT}/ireland/?leaseLength_to=3`,
            href: `/${SEARCH_URLS.RENT}/ireland/?leaseLength_to=3`,
          },
          {
            title: 'Parking Spaces',
            basePath: SEARCH_URLS.PARKING_RENT,
            href: `/${SEARCH_URLS.PARKING_RENT}/ireland`,
          },
        ],
      },
      {
        title: 'Commercial',
        href: '/commercial-property',
        basePath: [SEARCH_URLS.COMMERCIAL_RENT, SEARCH_URLS.COMMERCIAL_BUY],
        subItems: [
          {
            title: 'To Lease',
            basePath: SEARCH_URLS.COMMERCIAL_RENT,
            href: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland`,
          },
          {
            title: 'For Sale',
            basePath: SEARCH_URLS.COMMERCIAL_BUY,
            href: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland`,
          },
        ],
      },
      {
        title: 'Share',
        href: `/${SEARCH_URLS.SHARE}/ireland`,
        basePath: [SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE, SEARCH_URLS.SHARE],
        subItems: [
          {
            title: 'All Property',
            dontShowActiveForSubPaths: true,
            href: `/${SEARCH_URLS.SHARE}/ireland`,
            basePath: `/${SEARCH_URLS.SHARE}/ireland`,
          },
          {
            title: 'Student Accommodation',
            basePath: SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE,
            href: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE}/ireland`,
          },
        ],
      },
      {
        title: 'Sell',
        href: `https://www.sell.daft.ie/?utm_source=Daft+Sell+Nav&utm_medium=Sell+Menu+button`,
        opensInNewTab: true,
        subItems: [
          {
            title: 'How to sell my home',
            href: `https://sell.daft.ie?utm_source=Daft+Sell+Nav&utm_medium=how+to+sell+my+home+menu+item`,
            opensInNewTab: true,
          },
          {
            title: 'What is my home worth?',
            href: `/instant-valuation`,
            opensInNewTab: true,
          },
          {
            title: 'Get the Daft Advantage',
            href: `https://www.advantage.daft.ie?utm_source=Daft+Sell+Nav&utm_medium=get+the+daft+advantage+menu+item`,
            opensInNewTab: true,
          },
        ],
      },
    ] satisfies NavigationItemProps[],
    mortgagesNavConfig: ({
      isUserLoggedIn,
      hasBuyingBudget,
    }: {
      isUserLoggedIn: boolean;
      hasBuyingBudget: boolean;
    }): NavigationItemProps[] => [
      {
        title: 'Mortgages',
        subItems: [
          {
            title:
              isUserLoggedIn && hasBuyingBudget
                ? 'My Buying Budget'
                : 'Buying Budget Calculator',
            basePath: 'mortgages',
            href:
              isUserLoggedIn && hasBuyingBudget
                ? '/daft-mortgages/buying-budget/results?utm_source=daft&utm_medium=nav&utm_campaign=mortgages'
                : '/daft-mortgages/buying-budget?utm_source=daft&utm_medium=nav&utm_campaign=mortgages',
            opensInNewTab: true,
            dataTracking:
              isUserLoggedIn && hasBuyingBudget
                ? 'click_my_buying_budget'
                : 'click_buying_budget_calculator',
          },
          {
            title: 'Buying Checklist',
            basePath: 'buying-checklist',
            href: `/buying-checklist?utm_source=daft&utm_medium=nav&utm_campaign=mortgages`,
            opensInNewTab: true,
            dataTracking: 'click_buying_checklist',
          },
        ],
      },
    ],

    authenticatedAccountNavConfig: [
      {
        title: 'MyDaft',
        basePath: '/mydaft',
        subItems: [
          {
            title: 'Saved Properties',
            basePath: 'mydaft/saved-ads',
            href: '/mydaft/saved-ads',
          },
          {
            title: 'Saved Searches',
            basePath: 'mydaft/saved-searches',
            href: '/mydaft/saved-searches',
          },
          {
            title: 'Account Settings',
            basePath: '/mydaft/my-account',
            href: '/mydaft/my-account',
          },
          {
            title: 'My Buying Budget',
            basePath: 'mydaft/mortgages',
            href: `/daft-mortgages/buying-budget/results?utm_source=daft&utm_medium=nav&utm_campaign=mydaft`,
            opensInNewTab: true,
          },
          {
            title: 'My Created Ads',
            basePath: '/mydaft/my-created-ads',
            href: '/mydaft/my-created-ads',
          },
          {
            title: 'Sign out',
            href: '/auth/logout' + redirectBackToAfterLogOut,
          },
        ],
      },
    ],
    unAuthenticatedAccountNavConfig: [
      {
        title: 'Sign in',
        href: '/auth/authenticate',
        renderAs: 'anchor',
      },
    ],
    mobileUnAuthenticatedAccountNavConfig: [
      {
        title: 'MyDaft',
        basePath: '/mydaft',
        subItems: [
          {
            title: 'Sign in',
            href: '/auth/authenticate',
          },
        ],
      },
    ],
    placeAdConfig: [PLACE_AD],
  };
};

const getLogoConfig = ({
  alternativeStyle,
  pageVariant,
  isUserLoggedIn,
  hasBuyingBudget,
}: HeaderNavConfigProps) => {
  switch (pageVariant) {
    case 'VALUATION_TOOL':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/valuation-tool-logo.svg`,
        href: `/agents/valuation-tool-property-price-register`,
        alt: 'Daft Valuation Tool logo',
      };
    case 'AGENT_OFFERS':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/daft-logo${
          alternativeStyle ? '-all-white' : ''
        }.svg`,
        href: `/agents/offers/residential-for-sale`,
        alt: 'Daft logo',
      };
    case 'AD_PERFORMANCE_TOOL':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/cad-performance-tool-logo.svg`,
        href: `/agents/adperformance`,
        alt: 'Performance Tool logo',
      };
    case 'INTEL_TOOL':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/intel-tool-logo.svg`,
        href: `/agents/bi/supply/`,
        alt: 'Business Intelligence Tool logo',
      };
    case 'INTEL_TOOL_V2':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/intel-tool-logo.svg`,
        href: `/agent-v2/bi/supply/`,
        alt: 'Business Intelligence Tool logo',
      };
    case 'COMMERCIAL':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/daft-logo-commercial${
          alternativeStyle ? '-all-white' : ''
        }.svg`,
        href: `/commercial-property`,
        alt: 'Daft logo',
      };
    case 'DATA_HUB':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/data-hub-logo.svg`,
        href: `/agents/datahub`,
        alt: 'Data hub logo',
      };
    case 'PREMIER_PARTNER':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/premier-partner-logo.svg`,
        href: `/agents/premierpartner/advantage-ad-performance`,
        alt: 'Premier partner logo',
      };
    case 'DAFT_MORTGAGES':
      return {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/daft-logo.svg`,
        href:
          isUserLoggedIn && hasBuyingBudget
            ? '/daft-mortgages/buying-budget/results'
            : 'https://www.daft.ie/',
        alt: 'Daft logo',
      };
    default:
      return {
        url: `/static/images/daft-logo${
          alternativeStyle ? '-all-white' : ''
        }.svg`,
        href: `/`,
        alt: 'Daft logo',
      };
  }
};

export const getNavigationForSection = ({
  alternativeStyle,
  pageVariant,
  isUserLoggedIn,
  hasBuyingBudget,
}: HeaderNavConfigProps) => {
  const logoConfig = getLogoConfig({
    alternativeStyle,
    pageVariant,
    isUserLoggedIn,
    hasBuyingBudget,
  });
  switch (pageVariant) {
    case 'VALUATION_TOOL':
      return { ...valuationToolNavigation, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'VALUATION_TOOL_V2':
      return { ...valuationToolNavigationV2, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'AGENT_OFFERS':
      return { ...agentOffersNavigation, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'AGENT_OFFERS_V2':
      return { ...agentOffersNavigationV2, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'AD_PERFORMANCE_TOOL':
      return { ...adPerformaceNavigation, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'INTEL_TOOL':
      return { ...intelToolNavigation, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'INTEL_TOOL_V2':
      return { ...intelToolNavigationV2, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'DATA_HUB':
      return { ...dataHubNavigation, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'DATA_HUB_v2':
      return { ...dataHubNavigationV2, logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'PREMIER_PARTNER':
      return { logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    case 'PLACE_AD':
      return {
        ...defaultNavigation({
          redirectBackToAfterLogOut: `?redirectBackToAfterLogOut=${BASE_URL}`,
        }),
        logoConfig,
      } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
    default:
      return { ...defaultNavigation({}), logoConfig } as HeaderNavConfig & {
        logoConfig: LogoConfig;
      };
  }
};
